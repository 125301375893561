@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
 
@tailwind base;
@tailwind components;
@tailwind utilities;

 
*{box-sizing: border-box;
  font-family: "Poppins" !important;
  font-optical-sizing: auto;
   font-style: normal;
  font-variation-settings:
    "slnt" 0;
}


.single-digit-input::-webkit-outer-spin-button,
.single-digit-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
 
body{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Poppins' !important;
  background-color: white;
  font-family: Poppins !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.single-digit-input[type="number"] {
  -moz-appearance: textfield;
}

.single-digit-input :focus{
  outline: 2px solid rgba(230, 5, 103, 0.678);



}

.custom-outline:focus{
  outline: 0px solid rgba(255, 255, 255, 0.678);

}
 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: hsla(220, 43%, 11%, 0.5)
  ;
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listsearch:focus{

  border-width: 1px;
  border-style: solid;
  outline: none;
  border-radius:  5px;
  

}
.ModalContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: hsla(220, 43%, 11%, 0.5)
  ;
  z-index: 3000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadingSpinner {
  width: 70px;
  height: 70px;
   border: 8px solid;
   border-color: hsla(265, 100%, 25%, 1) transparent hsla(265, 100%, 25%, 1) transparent;
   border-radius: 100%;
  animation: spin 1.2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

 
::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the tracking area */
}

.fade {
  -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
}


::-webkit-scrollbar-thumb {
  background-color: #888; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
  border: 2px solid #f1f1f1; /* creates padding around thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* color of the thumb when hovered */
}
.overflow-y-scroll {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on iOS */
}

 
.CircularProgressbar-path {
  stroke: white !important;
}
.CircularProgressbar-trail {
  stroke: #5e638d  !important;
}
.CircularProgressbar-text {
  font-weight: bold;
  font-size: 16px !important;
  fill: white !important;
}
 
.CircularProgressbar-background {
  fill: green !important;
}

input[type="radio"]:checked {
  accent-color: #636AE8; /* Change this to your desired color */
}